import { Component, OnInit, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from "../../service/message.service";


@Component({
  selector: 'modal-content',
  templateUrl: './badgeholder.modal.component.html'
})

@Injectable({
  providedIn: 'root'
})
export class BadgeholderModalComponent implements OnInit {

  constructor(public modalService: NgbModal, private messageService: MessageService) { }

  isBadgeholder: boolean = false;
  airportCodeText1 = '';
  airportCodeText2 = '';

  ngOnInit() {

    //Checking to see which msg lert to popup
    if (this.messageService.message.includes('not-badgeholder')) 
      this.isBadgeholder = false;
    else 
      this.isBadgeholder = true;

    //Checking to see what airport code to display
    if (this.messageService.message.includes('-ONT')) {
      this.airportCodeText1 = 'ONT';
      this.airportCodeText2 = 'ONT+';
    }
    else if (this.messageService.message.includes('-PSP')) {
      this.airportCodeText1 = 'PSP';
      this.airportCodeText2 = 'PSP';
    }

  }

  close(reason) {
    this.modalService.dismissAll(reason);
  }
}
