import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'advisory-content',
  templateUrl: './advisory.component.html'
})
export class AdvisoryComponent implements OnInit {
  constructor(public modalService: NgbModal) { }

  ngOnInit() {
  }

  close(reason) {
    this.modalService.dismissAll(reason);
  }
}
