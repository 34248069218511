import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DeviceService {

  constructor() { }

  isMobile(): boolean {
    return /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent);
  }

}
