import { Guid } from "./system";
export class TravelDocument {
  ID: string = Guid.Empty;
  OwnerType: number = 0;
  OwnerID: string = Guid.Empty;
  SeqNo: number = 0;
  LastName: string = '';
  FirstName: string = '';
  MiddleName: string = '';
  TypeID: number = 0;
  DocCode: string = '';
  DocNo: string = '';
  DocExpiry!: Date;
  DocIssue: string = '';
  WhiteLightImage: string = '';
  InfraredImage: string = '';
  UltravioletImage: string = '';
  AuthenticationRiskFactor: number = 0;
  IsDocumentScanned!: boolean;
}
