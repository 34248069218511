import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { Visitor } from '../../model/visitor';
import { ViewText } from "../../model/viewText";
import { GatePassService } from '../../service/gatepass.service';

@Component({
  selector: 'confirm-content',
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements OnInit {
  @Input() visitors: Visitor[];
  @Input() locale: string;
  @Output() closeEvent = new EventEmitter<string>();
  viewText: ViewText = new ViewText();

  constructor(public modalService: NgbModal, private gatepassService: GatePassService) {
  }

  ngOnInit() {
    //We need to put a condistion to handle this for PHL only for now.
    this.loadLocalization(this.locale);
  }

  close(reason) {
    this.modalService.dismissAll(reason);
    this.closeEvent.emit(reason);
  }

  loadLocalization(culture: string) {
    this.gatepassService.getLocalization(culture, new ViewText())
      .toPromise()
      .then((result: ViewText) => {
        this.viewText = result;
      })
      .catch(error => { console.log(error); throw 'Error Loading the Localization'; });
  }
}
