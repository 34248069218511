import { Guid } from "./system";
import { TravelDocument } from "./travelDocument";

export class GatePass {
  ID: string = Guid.Empty;
  CarrierCode: string = '';
  AirportCode: string = '';
  TerminalID: string = '';
  ProcessingDate!: Date;
  ProcessingTime: string = '';
  RequestedTimestampUTC: string = '';
  FirstName: string = '';
  LastName: string = '';
  DOB: string = '';
  Gender: string = '';
  RequesterEmail: string = '';
  RequesterTelephone: string = '';
  IsOvernightWork: boolean = false;
  EscortName: string = '';
  EscortTelephone: string = '';
  EscortCompany: string = '';
  RecordLocator: string = '';
  IsVerified: boolean = false;
  VetResult: number = -1;
  Remarks: string = '';
  ProcessingDateTime: string = '';
  TravelDocument: TravelDocument = new TravelDocument();
}
