import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import {
  defineComponents,
  DocumentReaderDetailType,
  DocumentReaderService,
  EventActions,
  InternalScenarios
} from '../../../assets/main.js';

@Component({
  templateUrl: './regula.component.html'
})

export class RegulaComponent implements OnInit {

  @Input() scenario: any;
  @Input() visitorIndex: number;
  @Output() closeEvent = new EventEmitter<Map<string, string>>();
  fieldMap: Map<string, string> = new Map<string, string>();
  scanComplete: boolean = false;
  scanError: boolean;
  scanStatus: boolean = true;
  fName: string = '';
  gName: string = '';
  lName: string = '';
  dob: string = '';
  gender: string = '';
  docExpiry: string = '';
  errorMessage: string = 'FAILED TO PROCESS, PLEASE TRY AGAIN!';

  constructor(public modalService: NgbModal) { }

  ngOnInit() {

    window.RegulaDocumentSDK = new DocumentReaderService();

    window.RegulaDocumentSDK.recognizerProcessParam = {
      processParam: {
        scenario: this.scenario,
        multipageProcessing: true,
        returnUncroppedImage: true,
        returnPackageForReprocess: false,
        timeout: 999999,
      },
    };

    window.RegulaDocumentSDK.imageProcessParam = {
      processParam: {
        scenario: this.scenario,
        multipageProcessing: true,
        returnUncroppedImage: true,
        returnPackageForReprocess: false,
        timeout: 999999,
      },
    };

    //This is used in production
    defineComponents().then(() => window.RegulaDocumentSDK.initialize({ license: '' }));

    //In dev, you have to use the base64 dev license
    //defineComponents().then(() => window.RegulaDocumentSDK.initialize({ license: 'AAEAAEbqqPy7KW0Fv3DLPXmUSqlI9ocb3nGCAoaGPkCCiONsjo5ERHQO+pEPH3czSZQjVHgKm2hZPcXI7s3YaogGTkux0r2wVIpBXP0yCNMhNAr3GYkzx1WrKm2WOYe0zeFP8+av9hkz+u3CiEPeJTLSrfBTvJpHrKy9JHg6SwcLk+hzODRwgDgeJ1r6hLtMJlyBBSdoURHiZk2Y1vW8JBuqmd9ZjPp65krfitId+wBfu5rjSftDIPBw0VPvgRa+1huVuIuWVqwN39TTgQ/rta0Nsc6ZSa+2YnSS9EqGaJ93ehz2NzLm84yBgZVBr6SpghG9Yw8lKToCUxEeld4EHo2pLWLkAAAAAAAAEA/qwz6xZgMTozNJK6mkx7BTYdtyIY9/AqzJRGKV6iIN4bhA58kExuJ/25K5Pztd9qV86HyvxAXotEJ3+AuZjViBsBrN98XPDijHPcDLpJMg3NNko00Re+wN8cYuX/UmnVH+wNXYV7BY0t1UnxHEC1buUZr6MKtU5FMNq7PrvYmZSXOvxIaZmbFSCS6DK3NQm+iJr1FMeLM/L7hRu14m6RcyQUnFPCJbGY1GFROTCktonOMviMR3A6oZiQ8ce2R+W4F8wSs6J6R4rXzXjYQm0bBHZ21KdWQwiGar1fhhmSpw' }));
  }

  //This call captures events from the document-reader component
  documentReaderHandler(visitorIndex: number, data: CustomEvent<DocumentReaderDetailType>) {

    if (data.detail.action === 'PROCESS_FINISHED' && data.detail.data !== null) {

      var response = data.detail.data.response;

      //Array for container fields, this includes text, image info, face recognition, etc.
      var containerFields = {};

      //Array for text index fields, this includes name, dob, etc.
      var textIndexFields = {};

      //Only loop through the container if it has data
      if (response.lowLvlResponse.ContainerList.Count > 0) {

        containerFields = response.lowLvlResponse.ContainerList.List;

        //Need to loop through the container fields and check for result type 36, which is text index fields.
        //In the future we can use other result types for other different processes.
        for (const containerFieldKey in containerFields) {

          if (containerFields[containerFieldKey].result_type === 36) {

            textIndexFields = response.lowLvlResponse.ContainerList.List[containerFieldKey].Text.fieldList;

            for (const key in textIndexFields) {

              switch (textIndexFields[key].fieldName) {

                case 'Surname':
                  if (textIndexFields[key].value !== '') {
                    this.fieldMap.set("Surname", textIndexFields[key].value);
                    this.lName = textIndexFields[key].value;
                  }
                  break;

                case 'First name':
                  this.fieldMap.set("FirstName", textIndexFields[key].value);
                  this.fName = textIndexFields[key].value;
                  break;

                case 'Given Names':
                  this.fieldMap.set("GivenName", textIndexFields[key].value.split(" ")[0]);
                  this.gName = textIndexFields[key].value;
                  break;

                case 'Date of Birth':
                  this.fieldMap.set("DateOfBirth", textIndexFields[key].value);
                  this.dob = textIndexFields[key].value;
                  break;

                case 'Sex':
                  this.fieldMap.set("Gender", textIndexFields[key].value.toLowerCase());
                  this.gender = textIndexFields[key].value;
                  break;

                case 'Date of Expiry':
                  this.fieldMap.set("DateOfExpiry", textIndexFields[key].value);
                  this.docExpiry = textIndexFields[key].value;
                  break;

                case 'Document Number':
                  this.fieldMap.set("DocumentNumber", textIndexFields[key].value);
                  break;

                case 'Issuing State Code':
                  this.fieldMap.set("IssuingStateCode", textIndexFields[key].value);
                  break;
              }
            }
          }

          //Result type 37 is for images
          else if (containerFields[containerFieldKey].result_type === 37) {

            //Fetching the images scanned
            var images = containerFields[containerFieldKey].Images;

            //Looking through the fields and grabbing the front sie cropped copy of the document
            if (images.fieldList.length > 0) {
              images.fieldList.forEach(field => {
                if (field.fieldName === 'Document front side') {
                  field.valueList.forEach(fieldValues => {
                    if (fieldValues.containerType === 1 && fieldValues.lightIndex === 6 && fieldValues.pageIndex === 0) {
                      this.fieldMap.set("DocImage", fieldValues.value);
                    }
                  });
                }
              });
            }
          }
        }
      }

      //Need to validate data here, check for expiry dates, etc.
      if (this.isDocExpired(this.docExpiry)) {
        this.errorMessage = 'DOCUMENT EXPIRED!';
        this.scanStatus = false;
      }


      if (this.fieldMap.size >= 5 && this.scanStatus) {

        //Sometimes the first name is not there, so we use the given name.
        if (this.fName === '')
          this.fName = this.gName;

        this.closeEvent.emit(this.fieldMap);
        this.scanError = false;
      }
      else {
        this.scanError = true;
      }

      this.scanComplete = true;
    }
  }

  //This function is used to close the modal
  close(reason) {
    this.modalService.dismissAll(reason);
  }

  //Check for document expiry date
  isDocExpired(expiryDateString: string): boolean {

    const expiryDate = new Date(expiryDateString);
    const currentDate = new Date();

    return expiryDate < currentDate;
  }
}
