import { required, propObject, email, alpha } from "@rxweb/reactive-form-validators";

import { Guid } from "./system";
import { Manager } from "./manager";

export class Visitor {
  @required()
  ID: string = Guid.Empty;

  @required()
  FirstName: string = '';

  @required()
  LastName: string = '';

  @required()
  DateOfBirth: string = '';

  @required()
  Gender: string = '';

  @required()
  @email()
  Email: string = '';

  @required()
  @email()
  Email1: string = '';

  @required()
  Phone: string = '';

  @required()
  IsOvernightWork: boolean = false;

  Dob: string = '';
  RecordLocator: string = '';

  IsVerified: boolean = false;

  @propObject(Manager)
  Manager: Manager;

  @required()
  ReasonForVisit: string = '';

  @required()
  EscortingReason: string = '';

  @required()
  ReasonForVisitFreeText: string = '';

  @required()
  DocCode: string = '';

  @required()
  DocNo: string = '';

  @required()
  DocExpiry: '';

  @required()
  DocIssue: string = '';

  @required()
  WhiteLightImage: string = '';
}
