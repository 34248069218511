import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  templateUrl: './scan.confirm.component.html'
})

export class ScanConfirmComponent implements OnInit {

  @Input() values = new Map<string, string>();
  @Output() closeEvent = new EventEmitter<string>();

  fName: string;
  lName: string;
  dob: string;
  gender: string;
  docType: string;

  constructor(public modalService: NgbModal) { }

  ngOnInit() {

    this.values.forEach((value, key) => {

      switch (key) {
        case "FirstName":
          this.fName = value;
          break;
        case "Surname":
          this.lName = value;
          break;
        case "DateOfBirth":
          this.dob = value;
          break;
        case "Gender":
          this.gender = value;
          break;
      }
    });
  }

  close(reason) {
    this.modalService.dismissAll(reason);
    this.closeEvent.emit(reason);
  }

}
