import { propArray, required } from "@rxweb/reactive-form-validators";

import { Guid } from "./system";
import { Visitor } from "./visitor";

export class VisitorPass {
  @required()
  ID: string = Guid.Empty;

  @required()
  Date: string = '';

  @required()
  TerminalID: string = Guid.Empty;

  TerminalName: string = '';

  @required()
  TerminalGatePassAllotmentID: string = Guid.Empty;

  TerminalGatePassAllotmentText: string = '';

  @required()
  RecaptchaToken: string = '';

  @propArray(Visitor)
  Visitors: Visitor[];
}
