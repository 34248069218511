import { formatDate } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { trigger, style, animate, transition } from '@angular/animations';
import { RxFormArray, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { CalendarComponent, FocusEventArgs } from '@syncfusion/ej2-angular-calendars';
import { Router } from '@angular/router';

import '../../../extension/date.extension';
import { Guid } from "../../../model/system";
import { Context } from "../../../model/context";
import { Manager } from '../../../model/manager';
import { Visitor } from '../../../model/visitor';
import { Terminal } from '../../../model/terminal';
import { GatePass } from '../../../model/gatePass';
import { ViewText } from "../../../model/viewText";
import { ModalComponent } from "./../modal.component";
import { AppComponent } from "../../../app.component";
import { BadgeholderModalComponent } from "../badgeholder.modal.component";
import { VisitorPass } from '../../../model/visitorPass';
import { ConfirmComponent } from "./../confirm.component";
import { GatePassService } from '../../../service/gatepass.service';
import { TerminalGatePassAllotment } from '../../../model/terminalGatePassAllotment';
import { AdvisoryComponent } from "./../advisory.component";
import { MessageService } from "../../../service/message.service";
import { DataService } from '../../../service/data.service';
import { MaskedDateTimeService } from '@syncfusion/ej2-angular-calendars';

@Component({
  selector: 'app-home',
  templateUrl: './psp.component.html',
  providers: [MaskedDateTimeService],
  animations: [
    trigger('fadein', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ]),
      transition('false => true', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ])
    ]),
    trigger('fadeout', [
      transition('false => true', [
        animate(1000, style({ opacity: 0 }))
      ])
    ])
  ]
})

export class PspComponent implements OnInit {

  mainForm: FormGroup;
  context: Context = new Context();
  airportCode: string = '';
  terminals: Terminal[] = [];
  isTerminalCtrlFrozen: boolean = false;
  gatePassTerminalID: string = Guid.Empty;
  terminalGatePassAllotments: TerminalGatePassAllotment[] = [];
  canSubmit: boolean = false;
  isVisitorCountOverLimit: boolean = false;
  confirmationStatus: boolean[] = [];
  isWaiting = false;
  isMainPageLeaving = false;
  isConfirmPage: boolean = false;
  viewText: ViewText = new ViewText(); //Used for localizations
  todaysDate: Date;
  miminumDate: Date;
  maximumDate: Date;
  minDate: Date = new Date(1900, 0, 1);
  gatePassDate: string;
  isTextboxNotEmpty: boolean[][] = [];
  isAddingVisitor: boolean = false;
  isAddVisitorEnabled: boolean = false;
  isRemovingVisitor: boolean = false;
  isRemoveVisitorEnabled: boolean = false;
  datesOfBirth: Date[] = [];
  acceptAgreement = false;
  isMainPage: boolean = true;
  selectedLocate: string = 'en-us';
  captchaResponse: string = '';
  enableMaskSupport: boolean = true;
  showMinorError: boolean = false;
  showWorkerInfo: boolean[] = [];
  showManagerInfo: boolean[] = [];
  selectedValue = '';

  miminumDobDate: Date;
  maximumDobDate: Date;
  dobInvalidFlag: boolean[] = [];

  get visitorPass() {
    return <VisitorPass>this.mainForm.value;
  }

  get ctrlVisitors() {
    return <FormArray>this.mainForm.controls.Visitors;
  }

  dateMask = {
    guide: true,
    showMask: false,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };

  phoneMask = {
    guide: true,
    showMask: false,
    mask: ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  };

  constructor(private formBuilder: RxFormBuilder, private appComponent: AppComponent,
    private gatepassService: GatePassService, private modalService: NgbModal, private router: Router,
    private dataService: DataService, private messageService: MessageService) {
  }

  ngOnInit(): void {

    if (this.dataService.name !== 'PSP') {
      this.router.navigate(['..']);
    }

    //Setting our airport code.
    this.airportCode = this.dataService.name;

    var visitorPass = new VisitorPass();
    visitorPass.Visitors = [];

    this.todaysDate = new Date();
    this.todaysDate.setHours(0, 0, 0, 0);
    this.miminumDate = new Date();
    this.maximumDate = new Date();
    this.maximumDate.setDate(this.maximumDate.getDate() + 6);

    this.maximumDobDate = new Date();
    this.miminumDobDate = new Date("1900-01-01");

    var strInitDate = formatDate(this.miminumDate, 'MM/dd/yyyy', 'en-us');

    visitorPass.Date = strInitDate;
    this.gatePassDate = strInitDate;

    for (var i = 0; i < 6; i++) {
      this.isTextboxNotEmpty[i] = [];
      this.isTextboxNotEmpty[i].push(false);
    }

    this.appComponent.loadStyle(this.airportCode.toLowerCase() + '-styles.css');

    this.mainForm = this.formBuilder.formGroup(VisitorPass, visitorPass);
    this.mainForm.valueChanges.subscribe(f => { this.modelChanged(f) });

    //this.loadLocalization(this.selectedLocate);
    this.loadContext();
    this.loadParameters();

  }

  //Loading context
  loadContext() {
    this.gatepassService.getContext()
      .toPromise()
      .then((result: Context) => {
        this.context = result;
        let favIcon: HTMLLinkElement = document.querySelector('#lkFavicon');
        favIcon.href = result.Icon;
        this.addVisitor();
      })
      .catch(error => { console.log(error); throw 'Error Loading the Context'; });
  }

  //Loading params
  loadParameters() {
    this.gatepassService.getTerminals()
      .toPromise()
      .then((result: Terminal[]) => {
        this.terminals = result;

        if (result != null && result.length == 1) {
          this.isTerminalCtrlFrozen = true;
          this.mainForm.patchValue({ TerminalID: result[0].ID });
        }
      })
      .catch(error => { console.log(error); throw 'Error Loading Parameters'; });

    this.mainForm.controls['Date'].setValue(this.miminumDate);
  }

  //Adding visitors
  addVisitor() {
    var visitor = new Visitor();
    visitor.Manager = new Manager();

    this.showManagerInfo.push(true);
    var visitorForm = this.formBuilder.formGroup(Visitor, visitor);

    this.ctrlVisitors.push(visitorForm);

    this.showWorkerInfo.push(true);

    this.datesOfBirth.push(null);
    this.isRemoveVisitorEnabled = this.ctrlVisitors.length > 1;

    this.isAddingVisitor = true;
    setTimeout(() => { this.isAddingVisitor = false; }, 1000);
  }

  //Removing visitors
  removeVisitor() {
    var visitorsCounter = this.ctrlVisitors.length;
    if (visitorsCounter > 1) {
      this.ctrlVisitors.removeAt(visitorsCounter - 1);
      this.datesOfBirth.splice(visitorsCounter - 1, 1);
      this.showWorkerInfo.splice(visitorsCounter - 1, 1);
      this.showManagerInfo.splice(visitorsCounter - 1, 1);

      for (var i = 0; i < 6; i++) {
        this.isTextboxNotEmpty[i].splice(visitorsCounter - 1, 1);
      }

      visitorsCounter--;
    }

    this.isRemoveVisitorEnabled = visitorsCounter > 1;

    this.isRemovingVisitor = true;
    setTimeout(() => { this.isRemovingVisitor = false; }, 1000);
  }

  //Opening a popup modal
  openModal(event) {
    event.srcElement.blur();
    event.preventDefault();

    this.modalService.open(ModalComponent, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  //Confirmation view before saving
  openConfirm(event) {
    event.srcElement.blur();
    event.preventDefault();

    var modalResult = this.modalService.open(ConfirmComponent, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    modalResult.componentInstance.visitors = this.mainForm.value.Visitors;
    modalResult.componentInstance.locale = this.selectedLocate;
    modalResult.componentInstance.closeEvent.subscribe(($e) => {
      if ($e == 'submit')
        this.save();
    })
  }

  //Show mamager info (Ontario specific)
  toggleShowManagerInfo(visitorIndex) {
    this.showManagerInfo[visitorIndex] = !this.showManagerInfo[visitorIndex];
    this.modelChanged(null);

    if (!this.showManagerInfo[visitorIndex])
      this.openModal(event);
  }

  //Agreement toggle
  toggleAcceptAgreement() {
    this.acceptAgreement = !this.acceptAgreement;
    this.modelChanged(null);
  }

  //Checking to see if dob changed
  dobChanged(visitorIndex: number, e: any, type: number) {
    try {
      var value = (type == 0) ? e.srcElement.value : e.value;
      if (value != null)
        this.datesOfBirth[visitorIndex] = new Date(value);

      this.modelChanged(null);

      this.isDobValid(visitorIndex);
    }
    catch { }
  }

  //Making sure dob is valid
  isDobValid(visitorIndex: number) {
    var result = false;

    try {
      result = this.datesOfBirth[visitorIndex] != null && this.datesOfBirth[visitorIndex] > this.miminumDobDate && this.datesOfBirth[visitorIndex] < this.maximumDobDate;
    }
    catch { }

    this.dobInvalidFlag[visitorIndex] = result;

    return result;
  }

  //Checking to see if terminal is valid
  isTerminalValid() {
    var result = false;

    try {
      result = this.mainForm.value.TerminalID != Guid.Empty;
    }
    catch { }

    return result;
  }

  //Checking to see if email fields are matching
  isEmailMatching(visitorIndex: number) {
    var result = false;

    try {
      result = this.mainForm.value.Visitors[visitorIndex].Email == this.mainForm.value.Visitors[visitorIndex].Email1;
    }
    catch { }

    return result;
  }

  isTerminalGatePassAllotmentValid() {
    var result = false;

    try {
      result = this.mainForm.value.TerminalGatePassAllotmentID != Guid.Empty;
    }
    catch { }

    return result;
  }

  //Keyup feature for some of the fields
  onTextboxKeyUp(event: Event, ctrlIndex: number, visitorIndex: number) {
    var ctrlValue = (event.target as HTMLInputElement).value;

    this.isTextboxNotEmpty[ctrlIndex][visitorIndex] = (ctrlValue != '');
  }

  //Refresh page
  returnToHome() {
    location.reload();
  }

  //Resolving the captcha response
  resolved(captchaResponse: string) {

    if (this.isMainPage) {
      var visitorPass: VisitorPass = this.mainForm.value;
      visitorPass.RecaptchaToken = captchaResponse;
      this.captchaResponse = captchaResponse;

      this.modelChanged(visitorPass);
    }
  }

  onError(e) {
    var visitorPass: VisitorPass = this.mainForm.value;
    visitorPass.RecaptchaToken = '';
    this.captchaResponse = '';

    this.modelChanged(null);
  }

  modelChanged(visitorPass: VisitorPass) {

    if (visitorPass != null && (visitorPass.Date != this.gatePassDate || visitorPass.TerminalID != this.gatePassTerminalID)) {
      this.gatePassDate = visitorPass.Date;
      this.gatePassTerminalID = visitorPass.TerminalID;
      visitorPass.TerminalGatePassAllotmentID = Guid.Empty;

      var startDate = new Date(visitorPass.Date).toLocaleDateString();

      if (startDate != '' && this.gatePassTerminalID != Guid.Empty) {

        this.gatepassService.getTerminalGatePassAllotments(startDate, this.gatePassTerminalID)
          .toPromise()
          .then((result: TerminalGatePassAllotment[]) => {
            this.terminalGatePassAllotments = result;
            visitorPass.TerminalGatePassAllotmentID = Guid.Empty;
          })
          .catch(error => { console.log(error); throw 'Errror Loading TimeSlots'; });

      }
      else {
        this.terminalGatePassAllotments = [];
      }
    }

    if (this.mainForm.value != null) {
      this.canSubmit = false;
      this.isVisitorCountOverLimit = false;

      if (visitorPass == null)
        visitorPass = this.visitorPass;

      if (visitorPass.RecaptchaToken == '' && this.captchaResponse != '') {
        visitorPass.RecaptchaToken = this.captchaResponse;
      }

      visitorPass.TerminalGatePassAllotmentText = this.mainForm.controls['TerminalGatePassAllotmentID'].value;

      var isFirstNameValid: boolean[] = [];
      var isLastNameValid: boolean[] = [];
      var isPhoneValid: boolean[] = [];
      var isEmailValid: boolean[] = [];
      var isConfirmEmailMatching: boolean[] = [];
      var adultCount = 0;
      var minorCount = 0;

      for (var i = 0; i < visitorPass.Visitors.length; i++) {

        if (this.visitorAgeStatus(i) === 'Adult')
          adultCount++;
        else if (this.visitorAgeStatus(i) === 'Minor')
          minorCount++;

        var visitor = visitorPass.Visitors[i];
        var visitorForm = (<RxFormGroup>(<RxFormArray>this.mainForm.controls.Visitors).controls[i]).controls;

        isFirstNameValid[i] = visitorForm.FirstName.status == 'VALID';
        isLastNameValid[i] = visitorForm.LastName.status == 'VALID';
        isPhoneValid[i] = visitor.Phone == '' || visitorForm.Phone.status == 'VALID';
        isEmailValid[i] = visitorForm.Email.status == 'VALID' && visitorForm.Email1.status == 'VALID' && visitorForm.Email.value == visitorForm.Email1.value;
        isConfirmEmailMatching[i] = visitorForm.Email.value == visitorForm.Email1.value;

        this.canSubmit = isFirstNameValid[i] && isLastNameValid[i] && isEmailValid[i] && isPhoneValid[i] &&
          visitor.Gender != '' && this.isDobValid(i) && this.isEmailMatching(i) &&
          (this.mainForm.controls['TerminalID'].value != '00000000-0000-0000-0000-000000000000') && this.gatePassDate != '';


        if (this.canSubmit) {
          visitor.Dob = this.datesOfBirth[i].toStringShortDate();
        }

        //////////////////////////////////////
        if (this.canSubmit && !this.showManagerInfo[i]) {//Info: Don't change the logic of this flag
          var managerForm = (<RxFormGroup>visitorForm.Manager).controls;

          this.canSubmit = visitor.Manager.Name != '' && visitor.Manager.Phone != '' && managerForm.Phone.status == 'VALID' && visitor.Manager.CompanyName != '';
        }
        else if (this.showManagerInfo[i]) {//Info: Don't change the logic of this flag
          var manager = visitorPass.Visitors[i].Manager;

          manager.Name = '';
          manager.Phone = '';
          manager.CompanyName = '';
        }
        //////////////////////////////////////

        this.isAddVisitorEnabled = (i == 0 && this.canSubmit || i > 0);

        if (!this.canSubmit) break;
      }

      var allotmentmentCount = 0;
      var visitorCount = 0;

      var selectedTerminalGatePassAllotment = this.terminalGatePassAllotments.filter(a => a.ID == visitorPass.TerminalGatePassAllotmentID)[0];

      if (selectedTerminalGatePassAllotment != null) {

        allotmentmentCount = selectedTerminalGatePassAllotment.Allotment - selectedTerminalGatePassAllotment.IssuedPassCount;
        visitorCount = visitorPass.Visitors.length;

        //This is to disable button to add more pax
        if (visitorPass.Visitors.length >= allotmentmentCount) {
          this.isAddVisitorEnabled = false;
          this.isVisitorCountOverLimit = true;
        }

        //Making error msg for when we switch from allotments avalaible while having more pax for that time slot.
        this.isVisitorCountOverLimit = false;
        if (visitorPass.Visitors.length > allotmentmentCount) {
          this.isVisitorCountOverLimit = true;
        }
      }

      this.showMinorError = minorCount > 0 && adultCount === 0;

      this.canSubmit = this.canSubmit && selectedTerminalGatePassAllotment != null && allotmentmentCount > 0 &&
        this.acceptAgreement && visitorPass.RecaptchaToken != null && visitorPass.RecaptchaToken != '' &&
        visitorCount <= allotmentmentCount && !this.showMinorError;

    }
  }

  save() {
    this.isWaiting = true;
    this.canSubmit = false;

    var gatePasses: GatePass[] = [];
    var visitorPass = this.mainForm.value;

    var processingTime = '00:00:00';
    var processingDate = new Date();

    this.terminalGatePassAllotments.forEach((value, index) => {
      if (value.ID == visitorPass.TerminalGatePassAllotmentID) {
        processingTime = value.StartTime;
        processingDate = new Date(visitorPass.Date);
      }
    });

    const processTimeDate = [processingDate.toLocaleDateString(), processingTime];

    for (var i = 0; i < visitorPass.Visitors.length; i++) {
      var gatePass = new GatePass();

      gatePass.ID = visitorPass.ID;
      gatePass.ProcessingDate = processingDate;
      gatePass.ProcessingDateTime = processTimeDate.join(' ');
      gatePass.ProcessingTime = processingTime;
      gatePass.TerminalID = visitorPass.TerminalID;
      gatePass.FirstName = visitorPass.Visitors[i].FirstName.toUpperCase();
      gatePass.LastName = visitorPass.Visitors[i].LastName.toUpperCase();
      gatePass.DOB = this.datesOfBirth[i].toDateString();
      gatePass.Gender = visitorPass.Visitors[i].Gender.toUpperCase();
      gatePass.RequesterEmail = visitorPass.Visitors[i].Email;
      gatePass.RequesterTelephone = visitorPass.Visitors[i].Phone;
      gatePass.IsOvernightWork = visitorPass.Visitors[i].IsOvernightWork;
      gatePass.AirportCode = this.context.AirportCode;
      gatePass.Remarks = '';

      if (visitorPass.Visitors[i].Manager != null) {
        gatePass.EscortName = visitorPass.Visitors[i].Manager.Name.toUpperCase();
        gatePass.EscortTelephone = visitorPass.Visitors[i].Manager.Phone;
        gatePass.EscortCompany = visitorPass.Visitors[i].Manager.CompanyName;
      }

      gatePasses.push(gatePass);
    }

    this.gatepassService.saveGatePass(gatePasses).subscribe({
      next: (gatePasses: GatePass[]) => {

        this.confirmationStatus[0] =
          this.confirmationStatus[1] =
          this.confirmationStatus[2] = false;

        if (gatePasses != null && gatePasses.length > 0) {
          var visitorPass = this.mainForm.value;
          var date = processingDate;

          this.captchaResponse = '';
          visitorPass.Date = formatDate(date, 'yyyy-MM-dd', 'en-us');
          visitorPass.TerminalName = this.terminals.filter(t => t.ID == visitorPass.TerminalID)[0].Name;
          visitorPass.TerminalGatePassAllotmentText = this.terminalGatePassAllotments.filter(t => t.ID == visitorPass.TerminalGatePassAllotmentID)[0].TimesWindow;

          for (var i = 0; i < visitorPass.Visitors.length; i++)
            visitorPass.Visitors[i].RecordLocator = gatePasses[i].RecordLocator;

          this.confirmationStatus[1] = true;
        }

        this.isMainPageLeaving = true;
        setTimeout(() => {
          this.isMainPage = false;
          this.isConfirmPage = true;

          setTimeout(() => {
            this.canSubmit = true;
            this.isWaiting = false;
          }, 500);
        }, 1000);
      },
      error: (e) => {
        this.canSubmit = true; this.isWaiting = false; console.log(e);
      }
    });
  }

  visitDate() {
    this.mainForm.controls['Date'].setValue('');
  }

  selectBadgeStatus(value: string) {

    switch (value) {

      case '1':
        this.messageService.message = 'badgeholder-PSP';
        break;
      case '2':
        this.messageService.message = 'not-badgeholder-PSP';
        break;
      default:
        this.messageService.message = '';
        break;
    }

    if (this.messageService.message !== '')
      this.modalService.open(BadgeholderModalComponent, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  onBadgeStatusChange(selectedValue, visitorIndex) {

    //Getting elements from ui
    this.selectedValue = selectedValue;

    var batchholderOption = 'cbxBadgeholder-' + visitorIndex;
    var noneBadgeholderOption = 'cbxNoneBadgeholder-' + visitorIndex;

    var cbxBadgeholderElement = <HTMLInputElement>document.getElementById(batchholderOption);
    var cbxNoneBadgeholderElement = <HTMLInputElement>document.getElementById(noneBadgeholderOption);

    //Checking badgeholder status
    switch (this.selectedValue) {

      case 'badgeholder-' + visitorIndex:
        this.messageService.message = 'badgeholder-PSP';
        cbxNoneBadgeholderElement.checked = false;
        break;

      case 'notbadgeholder-' + visitorIndex:
        this.messageService.message = 'not-badgeholder-PSP';
        cbxBadgeholderElement.checked = false;
        break;

      default:
        this.messageService.message = '';
        break;
    }

    //Need to show the fields needed for badgeholders
    if (cbxBadgeholderElement.checked)
      this.showManagerInfo[visitorIndex] = false;
    else
      this.showManagerInfo[visitorIndex] = true;

    //We don't want to trigger the model to update
    this.modelChanged(null);

    if (!cbxBadgeholderElement.checked && !cbxNoneBadgeholderElement.checked) { this.messageService.message = ''; }

    //Output message based on the check box selected
    if (this.messageService.message !== '')
      this.modalService.open(BadgeholderModalComponent, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });

  }

  //Checking visitor age
  visitorAgeStatus(visitorIndex: number) {

    var result = 'NotSet';

    try {
      if (this.datesOfBirth.length > 0) {
        const today = new Date();
        const birthDate = new Date(this.datesOfBirth[visitorIndex]);

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        if (age >= 18)
          result = 'Adult';
        else if (age < 18)
          result = 'Minor';
      }
    }
    catch { }

    return result;
  }

}
