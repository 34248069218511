import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { RxFormBuilder } from "@rxweb/reactive-form-validators";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';

//import { MatInputModule, MatNativeDateModule, MatDatepickerModule, MatFormFieldModule, MatProgressSpinnerModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
/*import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';*/

import { AppComponent } from './app.component';
import { environment } from '../environments/environment.prod';
import { HomeComponent } from './component/home/home.component';
import { ModalComponent } from './component/home/modal.component';
import { ConfirmComponent } from './component/home/confirm.component';
import { AdvisoryComponent } from './component/home/advisory.component';
import { RegulaComponent } from './component/regula/regula.component';
import { BadgeholderModalComponent } from './component/home/badgeholder.modal.component';
import { DocumentReaderWebComponent, DocumentReaderService, } from '../assets/main.js';

//These are components for new separated page components
import { PhlComponent } from './component/home/phl/phl.component';
import { PspComponent } from './component/home/psp/psp.component';
import { McoComponent } from './component/home/mco/mco.component';
import { TulComponent } from './component/home/tul/tul.component';
import { OntComponent } from './component/home/ont/ont.component';
import { BnaComponent } from './component/home/bna/bna.component';
import { ReaderComponent } from './component/regula/reader.component';
import { ScanConfirmComponent } from './component/regula/scan.confirm.component';

declare global {
  interface Window {
    RegulaDocumentSDK: DocumentReaderService;
  }

  interface HTMLElementTagNameMap {
    'document-reader': DocumentReaderWebComponent;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ModalComponent,
    ConfirmComponent,
    AdvisoryComponent,
    BadgeholderModalComponent,
    PhlComponent,
    PspComponent,
    McoComponent,
    TulComponent,
    OntComponent,
    BnaComponent,
    ReaderComponent,
    RegulaComponent,
    ScanConfirmComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    TextMaskModule,
    NgbModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    DatePickerModule,
    /*    ComboBoxModule, */
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'phl', component: PhlComponent, pathMatch: 'full' },
      { path: 'psp', component: PspComponent, pathMatch: 'full' },
      { path: 'mco', component: McoComponent, pathMatch: 'full' },
      { path: 'tul', component: TulComponent, pathMatch: 'full' },
      { path: 'ont', component: OntComponent, pathMatch: 'full' },
      { path: 'bna', component: BnaComponent, pathMatch: 'full' },
    ])
  ],
  providers: [
    RxFormBuilder,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.captchaSiteKey,
      } as RecaptchaSettings
    }],
  entryComponents: [ModalComponent, ConfirmComponent, AdvisoryComponent, BadgeholderModalComponent, RegulaComponent, ScanConfirmComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
