import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DocumentReaderCaptureWebComponent, DocumentReaderWebComponent } from '../../../assets/main.js';
import { DeviceService } from '../../service/device.service';

@Component({
  selector: 'app-reader',
  templateUrl: './reader.component.html',
  styleUrls: ['./reader.component.css'],
})
export class ReaderComponent implements AfterViewInit {
  constructor(private deviceService: DeviceService) { }

  @ViewChild('reader', { static: false }) reader?: ElementRef<DocumentReaderWebComponent>;

  ngAfterViewInit() {

    if (!this.reader) return;

    this.reader.nativeElement.settings = {
      startScreen: false, //!this.deviceService.isMobile(),
      regulaLogo: false,
      captureMode: 'auto',
      changeCameraButton: true,
    };

  }
}
