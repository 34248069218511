import { Guid } from "./system";
import { pattern, required } from "@rxweb/reactive-form-validators";

export class Manager {
  @required()
  ID: string = Guid.Empty;

  @required()
  Name: string = '';

  @pattern({ expression: { 'phone': /^\(\d{3}\)\d{3}-\d{4}$/ } })
  Phone: string = '';

  @required()
  CompanyName: string = '';
}
