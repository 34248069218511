interface Date {
  toDate(): Date;
  toStringShortDate(): string;
  equalTo(date: Date): boolean;
}

Date.prototype.toDate = function (): Date {
  var date: Date = this;
  var result: Date = date;

  if (date != null)
    result = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

  return result;
}

Date.prototype.toStringShortDate = function (): string {
  var date: Date = this;
  var result: string = date.toLocaleString();

  if (date != null) {
    result = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0).toLocaleString();
    result = result.substr(0, result.indexOf(','));
  }

  return result;
}

Date.prototype.equalTo = function (date: Date): boolean {
  var result: boolean = false;

  if (date != null)
    result = this.getFullYear() == date.getFullYear() && this.getMonth() == date.getMonth() &&
      this.getDate() == date.getDate() && this.getHours() == date.getHours() &&
      this.getMinutes() == date.getMinutes();

  return result;
}
