import { formatDate } from "@angular/common";
import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { trigger, style, animate, transition } from '@angular/animations';
import { RxFormArray, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { CalendarComponent, FocusEventArgs } from '@syncfusion/ej2-angular-calendars';
import { Router } from '@angular/router';

import '../../../extension/date.extension';
import { Guid } from "../../../model/system";
import { Context } from "../../../model/context";
import { Manager } from '../../../model/manager';
import { Visitor } from '../../../model/visitor';
import { Terminal } from '../../../model/terminal';
import { GatePass } from '../../../model/gatePass';
import { ViewText } from "../../../model/viewText";
import { ModalComponent } from "./../modal.component";
import { AppComponent } from "../../../app.component";
import { VisitorPass } from '../../../model/visitorPass';
import { ConfirmComponent } from "./../confirm.component";
import { GatePassService } from '../../../service/gatepass.service';
import { TerminalGatePassAllotment } from '../../../model/terminalGatePassAllotment';
import { DataService } from '../../../service/data.service';
import { DeviceService } from '../../../service/device.service';
import { MaskedDateTimeService } from '@syncfusion/ej2-angular-calendars';
import { RegulaComponent } from "../../regula/regula.component";

@Component({
  selector: 'app-home',
  templateUrl: './tul.component.html',
  providers: [MaskedDateTimeService],
  animations: [
    trigger('fadein', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ]),
      transition('false => true', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ])
    ]),
    trigger('fadeout', [
      transition('false => true', [
        animate(1000, style({ opacity: 0 }))
      ])
    ])
  ]
})

export class TulComponent implements OnInit {
  mainForm: FormGroup;
  context: Context = new Context();
  airportCode: string = '';
  terminals: Terminal[] = [];
  isTerminalCtrlFrozen: boolean = false;
  gatePassTerminalID: string = Guid.Empty;
  terminalGatePassAllotments: TerminalGatePassAllotment[] = [];
  canSubmit: boolean = false;
  isVisitorCountOverLimit: boolean = false;
  confirmationStatus: boolean[] = [];
  isWaiting = false;
  isMainPageLeaving = false;
  isConfirmPage: boolean = false;
  viewText: ViewText = new ViewText(); //Used for localizations
  todaysDate: Date;
  miminumDate: Date;
  maximumDate: Date;
  minDate: Date = new Date(1900, 0, 1);
  gatePassDate: string;
  isTextboxNotEmpty: boolean[][] = [];
  isAddingVisitor: boolean = false;
  isAddVisitorEnabled: boolean = false;
  isRemovingVisitor: boolean = false;
  isRemoveVisitorEnabled: boolean = false;
  datesOfBirth: Date[] = [];
  acceptAgreement = false;
  isMainPage: boolean = true;
  selectedLocate: string = 'en-us';
  captchaResponse: string = '';
  enableMaskSupport: boolean = true;
  showMinorError: boolean = false;
  isOpen: boolean; //May need to make this false.

  //Flag for document scanning
  isDocScanned: boolean = false;
  docScanned: boolean[] = [];
  scanComplete: boolean[] = [];
  minorList: boolean[] = [];
  scanCounter: number = 0;
  dataCaptured: boolean = false;
  isRegulaScanRequired: boolean = true;

  //Flag to enable/disable the fields related to the document scanning
  isDisabled: boolean = true;

  mininumDobDate: Date;
  mininumDobDates: Date[] = [];
  maximumDobDate: Date;
  dobInvalidFlag: boolean[] = [];

  get visitorPass() {
    return <VisitorPass>this.mainForm.value;
  }

  get ctrlVisitors() {
    return <FormArray>this.mainForm.controls.Visitors;
  }

  dateMask = {
    guide: true,
    showMask: false,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };

  phoneMask = {
    guide: true,
    showMask: false,
    mask: ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  };

  constructor(private formBuilder: RxFormBuilder, private appComponent: AppComponent,
    private gatepassService: GatePassService, private modalService: NgbModal, private router: Router,
    private dataService: DataService, private deviceService: DeviceService) {
  }

  ngOnInit(): void {

    if (this.dataService.name !== 'TUL') {
      this.router.navigate(['..']);
    }

    ///////////////////////////////////////////////
    this.isOpen = false; //We opted to always turn camera right away.

    //Checking if the device is mobile
    //if (!this.deviceService.isMobile()) {
    //  this.isOpen = true;
    //} else {
    //  this.isOpen = false;
    //}
    ///////////////////////////////////////////////

    //Need to check for regula scanner setting for requirement and enable/disable fields.
    //Don't forget to set it on the portal.
    this.gatepassService.getIsDocumentScanedRequired()
      .toPromise()
      .then((result: boolean) => {
        this.isRegulaScanRequired = result;
      })
      .catch(error => {
        console.log(error); throw 'Errror Loading Regula Scan Setting';
      });

    //Setting our airport code.
    this.airportCode = this.dataService.name;

    var visitorPass = new VisitorPass();
    visitorPass.Visitors = [];

    this.todaysDate = new Date();
    this.todaysDate.setHours(0, 0, 0, 0);
    this.miminumDate = new Date();
    this.maximumDate = new Date();
    this.maximumDate.setDate(this.maximumDate.getDate() + 6);

    this.maximumDobDate = new Date();
    this.mininumDobDate = new Date("1900-01-01");

    this.mininumDobDates[0] = new Date("1900-01-01"); //Setting the minimum date for the first visitor

    var strInitDate = formatDate(this.miminumDate, 'MM/dd/yyyy', 'en-us');

    visitorPass.Date = strInitDate;
    this.gatePassDate = strInitDate;

    for (var i = 0; i < 6; i++) {
      this.isTextboxNotEmpty[i] = [];
      this.isTextboxNotEmpty[i].push(false);
    }

    this.appComponent.loadStyle(this.airportCode.toLowerCase() + '-styles.css');

    this.mainForm = this.formBuilder.formGroup(VisitorPass, visitorPass);
    this.mainForm.valueChanges.subscribe(f => { this.modelChanged(f) });

    //this.loadLocalization(this.selectedLocate);
    this.loadContext();
    this.loadParameters();
  }

  //Loading context
  loadContext() {
    this.gatepassService.getContext()
      .toPromise()
      .then((result: Context) => {
        this.context = result;
        let favIcon: HTMLLinkElement = document.querySelector('#lkFavicon');
        favIcon.href = result.Icon;
        this.addVisitor();
      })
      .catch(error => { console.log(error); throw 'Error Loading the Context'; });

    this.mainForm.controls['Date'].setValue(this.miminumDate);
  }

  //Loading params
  loadParameters() {
    this.gatepassService.getTerminals()
      .toPromise()
      .then((result: Terminal[]) => {
        this.terminals = result;

        if (result != null && result.length == 1) {
          this.isTerminalCtrlFrozen = true;
          this.mainForm.patchValue({ TerminalID: result[0].ID });
        }
      })
      .catch(error => { console.log(error); throw 'Error Loading Parameters'; });
  }

  //Adding visitors
  addVisitor() {
    var visitor = new Visitor();
    visitor.Manager = new Manager();

    visitor.Email = this.visitorPass.Visitors.length > 0 ? this.visitorPass.Visitors[0].Email : '';
    visitor.Email1 = this.visitorPass.Visitors.length > 0 ? this.visitorPass.Visitors[0].Email : '';

    var visitorForm = this.formBuilder.formGroup(Visitor, visitor);
    this.ctrlVisitors.push(visitorForm);

    this.mininumDobDates.push(new Date("1900-01-01"));
    this.datesOfBirth.push(null);
    this.docScanned.push(null);
    this.scanComplete.push(null);
    this.minorList.push(null);
    this.isRemoveVisitorEnabled = this.ctrlVisitors.length > 1;

    this.isAddingVisitor = true;
    setTimeout(() => { this.isAddingVisitor = false; }, 1000);
  }

  //Removing visitors
  removeVisitor() {
    var visitorsCounter = this.ctrlVisitors.length;
    if (visitorsCounter > 1) {
      this.ctrlVisitors.removeAt(visitorsCounter - 1);
      this.datesOfBirth.splice(visitorsCounter - 1, 1);
      this.docScanned.splice(visitorsCounter - 1, 1);
      this.scanComplete.splice(visitorsCounter - 1, 1);
      this.minorList.splice(visitorsCounter - 1, 1);
      this.mininumDobDates.splice(visitorsCounter - 1, 1);

      for (var i = 0; i < 6; i++) {
        this.isTextboxNotEmpty[i].splice(visitorsCounter - 1, 1);
      }

      visitorsCounter--;
    }

    this.isRemoveVisitorEnabled = visitorsCounter > 1;

    this.isRemovingVisitor = true;
    setTimeout(() => { this.isRemovingVisitor = false; }, 1000);
  }

  //Opening a popup modal
  openModal(event) {
    event.srcElement.blur();
    event.preventDefault();

    this.modalService.open(ModalComponent, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  //Confirmation view before saving
  openConfirm(event) {
    event.srcElement.blur();
    event.preventDefault();

    var modalResult = this.modalService.open(ConfirmComponent, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    modalResult.componentInstance.visitors = this.mainForm.value.Visitors;
    modalResult.componentInstance.locale = this.selectedLocate;
    modalResult.componentInstance.closeEvent.subscribe(($e) => {
      if ($e == 'submit')
        this.save();
    })
  }

  //Open Regula scanner component
  openRegulaScanner(visitorIndex: number, scanType: string, event) {

    event.srcElement.blur();
    event.preventDefault();

    var fName = '', gName = '', lName = '', dob = '', gender = '', docExpiry = '', docNo = '', docImage = '', docIssue = '';

    var modalResult = this.modalService.open(RegulaComponent, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });

    if (scanType == 'passport') {
      modalResult.componentInstance.scenario = 'MrzAndLocate';
    }
    else if (scanType == 'license') {
      modalResult.componentInstance.scenario = 'BarcodeAndLocate';
    }

    modalResult.componentInstance.closeEvent.subscribe(($e) => {

      var visitorForm = (<RxFormGroup>(<RxFormArray>this.mainForm.controls.Visitors).controls[visitorIndex]).controls;

      $e.forEach((value, key) => {
        switch (key) {
          case 'Surname':
            lName = value;
            break;
          case 'FirstName':
            fName = value;
            break;
          case 'GivenName':
            gName = value;
            break;
          case 'DateOfBirth':
            dob = value;
            break;
          case 'Gender':
            gender = value;
            break;
          case 'DateOfExpiry':
            docExpiry = value;
            break;
          case 'DocumentNumber':
            docNo = value;
            break;
          case 'DocImage':
            docImage = value;
            break;
          case 'IssuingStateCode':
            docIssue = value;
            break;
        }
      });

      if (lName !== '' && (fName !== '' || gName !== '') && dob !== '' && gender !== '') {

        //Surname
        visitorForm.LastName.setValue(lName);

        //First name
        if (fName !== '')
          visitorForm.FirstName.setValue(fName);
        else
          visitorForm.FirstName.setValue(gName);

        //Date of birth
        this.addDobScan(visitorIndex, dob);
        const [year, month, day] = dob.split('-').map(Number);
        const localDate = new Date(year, month - 1, day);
        visitorForm.DateOfBirth.setValue(localDate);

        //Gender
        visitorForm.Gender.setValue(gender);

        //Document info

        if (scanType == 'passport') {
          visitorForm.DocCode.setValue('P');
        }
        else if (scanType == 'license') {
          visitorForm.DocCode.setValue('DL');
        }

        visitorForm.DocNo.setValue(docNo);
        visitorForm.DocExpiry.setValue(docExpiry);
        visitorForm.WhiteLightImage.setValue(docImage);
        visitorForm.DocIssue.setValue(docIssue);

        this.docScanned[visitorIndex] = true;
        this.scanComplete[visitorIndex] = true;
        this.minorList[visitorIndex] = false;
        this.scanCounter = 0;
        this.modelChanged(null);
      }
      else {
        this.scanCounter++;
      }
    });
  }

  toggleDocScanOverride(visitorIndex: number, isChecked: boolean) {

    var visitorForm = (<RxFormGroup>(<RxFormArray>this.mainForm.controls.Visitors).controls[visitorIndex]).controls;

    visitorForm.FirstName.setValue('');
    visitorForm.LastName.setValue('');
    visitorForm.DateOfBirth.setValue('');
    visitorForm.Gender.setValue('');

    if (isChecked) {
      this.docScanned[visitorIndex] = true;
      this.minorList[visitorIndex] = true;

      const currentDate = new Date();
      this.mininumDobDates[visitorIndex] = new Date();
      this.mininumDobDates[visitorIndex].setFullYear(currentDate.getFullYear() - 18);
    }
    else {
      this.mininumDobDates[visitorIndex] = new Date("1900-01-01");

      this.docScanned[visitorIndex] = false;
      this.minorList[visitorIndex] = false;
    }

    this.modelChanged(null);
  }

  setDobCheck(visitorIndex: number, isChecked: boolean) {

  }

  //Agreement toggle
  toggleAcceptAgreement() {
    this.acceptAgreement = !this.acceptAgreement;
    this.modelChanged(null);
  }

  maskDate(value: string): string {
    try {
      // Remove any non-digit characters
      value = value.replace(/\D/g, '');
    } catch (error) {
      console.error("Error during replace:", error);
      return value; // Return the original value if there's an error
    }

    // Format as MM/DD/YYYY
    if (value.length >= 2) {
      value = `${value.slice(0, 2)}/${value.slice(2)}`; // MM/...
    }
    if (value.length >= 5) {
      value = `${value.slice(0, 5)}/${value.slice(5, 9)}`; // MM/DD/YYYY
    }

    return value;
  }

  //Checking to see if dob changed
  dobChanged(visitorIndex: number, e: any, type: number) {
    try {

      const input = e.target as HTMLInputElement;

      var value = (type == 0) ? e.srcElement.value : e.value;
      if (value != null)
        this.datesOfBirth[visitorIndex] = new Date(value);

      this.modelChanged(null);

      this.isDobValid(visitorIndex);

      input.value = this.maskDate(input.value);

      this.mainForm.controls['DateOfBirth'].setValue(input.value);
    }
    catch { }
  }

  addDobScan(visitorIndex: number, value: string) {
    try {
      if (value != null) {

        // Split the date string into components
        const [year, month, day] = value.split('-').map(Number);

        // Create a date in the local timezone, set to midnight
        const localDate = new Date(year, month - 1, day);
        this.datesOfBirth[visitorIndex] = localDate;
      }

      this.modelChanged(null);

      this.isDobValid(visitorIndex);
    }
    catch { }
  }

  //Making sure dob is valid
  isDobValid(visitorIndex: number) {
    var result = false;

    try {
      result = this.datesOfBirth[visitorIndex] != null && this.datesOfBirth[visitorIndex] > this.mininumDobDates[visitorIndex] && this.datesOfBirth[visitorIndex] < this.maximumDobDate;
    }
    catch { }

    this.dobInvalidFlag[visitorIndex] = result;

    return result;
  }

  //Checking to see if terminal is valid
  isTerminalValid() {
    var result = false;

    try {
      result = this.mainForm.value.TerminalID != Guid.Empty;
    }
    catch { }

    return result;
  }

  //Checking to see if email fields are matching
  isEmailMatching(visitorIndex: number) {
    var result = false;

    try {
      result = this.mainForm.value.Visitors[visitorIndex].Email == this.mainForm.value.Visitors[visitorIndex].Email1;
    }
    catch { }

    return result;
  }

  isTerminalGatePassAllotmentValid() {
    var result = false;

    try {
      result = this.mainForm.value.TerminalGatePassAllotmentID != Guid.Empty;
    }
    catch { }

    return result;
  }

  //Keyup feature for some of the fields
  onTextboxKeyUp(event: Event, ctrlIndex: number, visitorIndex: number) {
    var ctrlValue = (event.target as HTMLInputElement).value;

    this.isTextboxNotEmpty[ctrlIndex][visitorIndex] = (ctrlValue != '');
  }

  //Refresh page
  returnToHome() {
    location.reload();
  }

  //Resolving the captcha response
  resolved(captchaResponse: string) {

    if (this.isMainPage) {
      var visitorPass: VisitorPass = this.mainForm.value;
      visitorPass.RecaptchaToken = captchaResponse;
      this.captchaResponse = captchaResponse;

      this.modelChanged(visitorPass);
    }
  }

  onError(e) {
    var visitorPass: VisitorPass = this.mainForm.value;
    visitorPass.RecaptchaToken = '';
    this.captchaResponse = '';

    this.modelChanged(null);
  }

  modelChanged(visitorPass: VisitorPass) {

    if (visitorPass != null && (visitorPass.Date != this.gatePassDate || visitorPass.TerminalID != this.gatePassTerminalID)) {
      this.gatePassDate = visitorPass.Date;
      this.gatePassTerminalID = visitorPass.TerminalID;
      visitorPass.TerminalGatePassAllotmentID = Guid.Empty;

      var startDate = new Date(visitorPass.Date).toLocaleDateString();

      if (startDate != '' && this.gatePassTerminalID != Guid.Empty) {

        this.gatepassService.getTerminalGatePassAllotments(startDate, this.gatePassTerminalID)
          .toPromise()
          .then((result: TerminalGatePassAllotment[]) => {
            this.terminalGatePassAllotments = result;
            visitorPass.TerminalGatePassAllotmentID = Guid.Empty;
          })
          .catch(error => { console.log(error); throw 'Errror Loading TimeSlots'; });

      }
      else {
        this.terminalGatePassAllotments = [];
      }
    }

    if (this.mainForm.value != null) {
      this.canSubmit = false;
      this.isVisitorCountOverLimit = false;

      if (visitorPass == null)
        visitorPass = this.visitorPass;

      if (visitorPass.RecaptchaToken == '' && this.captchaResponse != '') {
        visitorPass.RecaptchaToken = this.captchaResponse;
      }

      visitorPass.TerminalGatePassAllotmentText = this.mainForm.controls['TerminalGatePassAllotmentID'].value;

      var isFirstNameValid: boolean[] = [];
      var isLastNameValid: boolean[] = [];
      var isPhoneValid: boolean[] = [];
      var isEmailValid: boolean[] = [];
      var isConfirmEmailMatching: boolean[] = [];
      var adultCount = 0;
      var minorCount = 0;

      for (var i = 0; i < visitorPass.Visitors.length; i++) {

        if (this.visitorAgeStatus(i) === 'Adult') {
          adultCount++;
        }
        else if (this.visitorAgeStatus(i) === 'Minor') {
          minorCount++;
        }

        var visitor = visitorPass.Visitors[i];
        var visitorForm = (<RxFormGroup>(<RxFormArray>this.mainForm.controls.Visitors).controls[i]).controls;

        isFirstNameValid[i] = visitorForm.FirstName.status == 'VALID';
        isLastNameValid[i] = visitorForm.LastName.status == 'VALID';
        isPhoneValid[i] = visitor.Phone == '' || visitorForm.Phone.status == 'VALID';
        isEmailValid[i] = visitorForm.Email.status == 'VALID' && visitorForm.Email1.status == 'VALID' && visitorForm.Email.value == visitorForm.Email1.value;
        isConfirmEmailMatching[i] = visitorForm.Email.value == visitorForm.Email1.value;

        this.canSubmit = isFirstNameValid[i] && isLastNameValid[i] && isEmailValid[i] && isPhoneValid[i] && visitor.Gender != '' && this.isDobValid(i) && this.isEmailMatching(i) && (this.mainForm.controls['TerminalID'].value != '00000000-0000-0000-0000-000000000000');

        if (this.canSubmit) {
          visitor.Dob = this.datesOfBirth[i].toStringShortDate();
        }

        this.isAddVisitorEnabled = (i == 0 && this.canSubmit || i > 0);

        if (!this.canSubmit) break;
      }

      var allotmentmentCount = 0;
      var visitorCount = 0;

      var selectedTerminalGatePassAllotment = this.terminalGatePassAllotments.filter(a => a.ID == visitorPass.TerminalGatePassAllotmentID)[0];

      if (selectedTerminalGatePassAllotment != null) {

        allotmentmentCount = selectedTerminalGatePassAllotment.Allotment - selectedTerminalGatePassAllotment.IssuedPassCount;
        visitorCount = visitorPass.Visitors.length;

        //This is to disable button to add more pax
        if (visitorPass.Visitors.length >= allotmentmentCount) {
          this.isAddVisitorEnabled = false;
          this.isVisitorCountOverLimit = true;
        }

        //Making error msg for when we switch from allotments avalaible while having more pax for that time slot.
        this.isVisitorCountOverLimit = false;
        if (visitorPass.Visitors.length > allotmentmentCount) {
          this.isVisitorCountOverLimit = true;
        }
      }

      this.showMinorError = minorCount > 0 && adultCount === 0;

      this.canSubmit = this.canSubmit && selectedTerminalGatePassAllotment != null && allotmentmentCount > 0 &&
        this.acceptAgreement && visitorPass.RecaptchaToken != null && visitorPass.RecaptchaToken != '' &&
        visitorCount <= allotmentmentCount && !this.showMinorError;

    }
  }

  isValidDateFormat(dateString: string): boolean {
    // Regular expression to check if the date is in YYYY-MM-DD format
    const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/;

    // Test the date string against the regex
    if (!regex.test(dateString)) {
      return false; // Not matching the format
    }

    return true;
  }

  //Checking visitor age
  visitorAgeStatus(visitorIndex: number) {

    var result = 'NotSet';

    try {
      if (this.datesOfBirth.length > 0) {
        const today = new Date();
        const birthDate = new Date(this.datesOfBirth[visitorIndex]);

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }

        if (age >= 18)
          result = 'Adult';
        else if (age < 18)
          result = 'Minor';
      }
    }
    catch { }

    return result;
  }

  save() {
    this.isWaiting = true;
    this.canSubmit = false;

    var gatePasses: GatePass[] = [];
    var visitorPass = this.mainForm.value;

    var processingTime = '00:00:00';
    var processingDate = new Date();

    this.terminalGatePassAllotments.forEach((value, index) => {
      if (value.ID == visitorPass.TerminalGatePassAllotmentID) {
        processingTime = value.StartTime;
        processingDate = new Date(visitorPass.Date);
      }
    });

    const processTimeDate = [processingDate.toLocaleDateString(), processingTime];

    for (var i = 0; i < visitorPass.Visitors.length; i++) {
      var gatePass = new GatePass();

      gatePass.ID = visitorPass.ID;
      gatePass.ProcessingDate = processingDate;
      gatePass.ProcessingDateTime = processTimeDate.join(' ');
      gatePass.ProcessingTime = processingTime;
      gatePass.TerminalID = visitorPass.TerminalID;
      gatePass.FirstName = visitorPass.Visitors[i].FirstName.toUpperCase();
      gatePass.LastName = visitorPass.Visitors[i].LastName.toUpperCase();
      gatePass.DOB = this.datesOfBirth[i].toDateString();
      gatePass.Gender = visitorPass.Visitors[i].Gender.toUpperCase();
      gatePass.RequesterEmail = visitorPass.Visitors[i].Email;
      gatePass.RequesterTelephone = visitorPass.Visitors[i].Phone;
      gatePass.IsOvernightWork = visitorPass.Visitors[i].IsOvernightWork;
      gatePass.AirportCode = this.context.AirportCode;
      gatePass.Remarks = '';

      //Saving scanned document stuff if it's not a minor
      if (!this.minorList[i]) {
        gatePass.TravelDocument.FirstName = visitorPass.Visitors[i].FirstName.toUpperCase();
        gatePass.TravelDocument.LastName = visitorPass.Visitors[i].LastName.toUpperCase();
        gatePass.TravelDocument.DocNo = visitorPass.Visitors[i].DocNo;
        gatePass.TravelDocument.DocExpiry = visitorPass.Visitors[i].DocExpiry;
        gatePass.TravelDocument.WhiteLightImage = visitorPass.Visitors[i].WhiteLightImage;
        gatePass.TravelDocument.OwnerType = 5;
        gatePass.TravelDocument.IsDocumentScanned = true;
        gatePass.TravelDocument.DocCode = visitorPass.Visitors[i].DocCode;
        gatePass.TravelDocument.DocIssue = visitorPass.Visitors[i].DocIssue;

        if (visitorPass.Visitors[i].DocCode === 'P') {
          gatePass.TravelDocument.TypeID = 1;
        }
        else if (visitorPass.Visitors[i].DocCode === 'DL') {
          gatePass.TravelDocument.TypeID = 18;
        }
      }

      gatePasses.push(gatePass);
    }

    this.gatepassService.saveGatePass(gatePasses).subscribe({
      next: (gatePasses: GatePass[]) => {

        this.confirmationStatus[0] =
          this.confirmationStatus[1] =
          this.confirmationStatus[2] = false;

        if (gatePasses != null && gatePasses.length > 0) {
          var visitorPass = this.mainForm.value;
          var date = processingDate;

          this.captchaResponse = '';
          visitorPass.Date = formatDate(date, 'yyyy-MM-dd', 'en-us');
          visitorPass.TerminalName = this.terminals.filter(t => t.ID == visitorPass.TerminalID)[0].Name;
          visitorPass.TerminalGatePassAllotmentText = this.terminalGatePassAllotments.filter(t => t.ID == visitorPass.TerminalGatePassAllotmentID)[0].TimesWindow;

          for (var i = 0; i < visitorPass.Visitors.length; i++)
            visitorPass.Visitors[i].RecordLocator = gatePasses[i].RecordLocator;

          this.confirmationStatus[1] = true;
        }

        this.isMainPageLeaving = true;
        setTimeout(() => {
          this.isMainPage = false;
          this.isConfirmPage = true;

          setTimeout(() => {
            this.canSubmit = true;
            this.isWaiting = false;
          }, 500);
        }, 1000);
      },
      error: (e) => {
        this.canSubmit = true; this.isWaiting = false; console.log(e);
      }
    });
  }
}
