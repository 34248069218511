import { ViewText } from "./viewText";

export class Context {
  Icon: string = '';
  Logo: string = '';
  LinkFAQ: string = '';
  AirportCode: string = '';
  LinkPrivacy: string = '';
  LinkTermAndConditions: string = '';
  ViewText: ViewText = null;
}
