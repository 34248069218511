import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Context } from '../model/context';
import { Terminal } from '../model/terminal';
import { GatePass } from '../model/gatePass';
import { ViewText } from '../model/viewText';
import { TerminalGatePassAllotment } from '../model/terminalGatePassAllotment';

@Injectable({ providedIn: 'root' })
export class GatePassService {

  constructor(private httpClient: HttpClient) { }

  getContext(): Observable<Context> {
    return this.httpClient.get<Context>("gatepass/getcontext/");
  }

  getLocalization(culture: string, viewText: ViewText): Observable<ViewText> {
    return this.httpClient.post<ViewText>("gatepass/getlocalization/", { culture: culture, viewText: viewText });
  }

  getTerminals(): Observable<Terminal[]> {
    return this.httpClient.get<Terminal[]>("gatepass/getterminals/");
  }

  getTerminalGatePassAllotments(startDate: string, terminalId: string): Observable<TerminalGatePassAllotment[]> {
    let params: HttpParams = new HttpParams();
    params = params.set("startdate", startDate);
    params = params.set("terminalid", terminalId);

    return this.httpClient.get<TerminalGatePassAllotment[]>("gatepass/getterminalgatepassallotments/", { params: params });
  }

  getTerminalGatePassAllotmentByDay(startDate: string, terminalId: string): Observable<TerminalGatePassAllotment[]> {
    let params: HttpParams = new HttpParams();
    params = params.set("startdate", startDate);
    params = params.set("terminalid", terminalId);

    return this.httpClient.get<TerminalGatePassAllotment[]>("gatepass/getterminalgatepassallotmentbyday/", { params: params });
  }

  saveGatePass(gatePasses: GatePass[]): Observable<GatePass[]> {
    return this.httpClient.post<GatePass[]>("gatepass/savegatepasses", gatePasses);
  }

  getAppleBoardingPass(visitorId: string): Observable<string> {
    return this.httpClient.post<string>("gatepass/getappleboardingpass", visitorId);
  }

  getGoogleBoardingPass(visitorId: string): Observable<string> {
    return this.httpClient.post<string>("gatepass/getgoogleboardingpass", visitorId);
  }

  getIsDocumentScanedRequired(): Observable<boolean> {
    return this.httpClient.get<boolean>("gatepass/isdocumentscanrequired");
  }
}
