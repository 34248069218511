import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-content',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit {
  constructor(public modalService: NgbModal) { }
  
  ngOnInit() {
  }

  close(reason) {
    this.modalService.dismissAll(reason);
  }
}
