import { Component, OnInit, Injectable, EventEmitter, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { trigger, style, animate, transition } from '@angular/animations';
import { RxFormArray, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Router } from '@angular/router';
import '../../extension/date.extension';
import { Context } from "../../model/context";
import { AppComponent } from "../../app.component";
import { GatePassService } from '../../service/gatepass.service';
import { DataService } from '../../service/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: [
    trigger('fadein', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ]),
      transition('false => true', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ])
    ]),
    trigger('fadeout', [
      transition('false => true', [
        animate(1000, style({ opacity: 0 }))
      ])
    ])
  ]
})

@Injectable({
  providedIn: 'root',
})

export class HomeComponent implements OnInit {
  mainForm: FormGroup;
  context: Context = new Context();
  isLoading: boolean = false;

  @Output() messageEvent = new EventEmitter<string>();
  //public datepickerObj?: CalendarComponent;

  constructor(private formBuilder: RxFormBuilder, private appComponent: AppComponent,
    private gatepassService: GatePassService, private modalService: NgbModal, private router: Router, private SVC: DataService) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadContext();
  }

  loadContext() {
    this.gatepassService.getContext()
      .toPromise()
      .then((result: Context) => {

        //Getting our airport context
        this.context = result;

        //Routing to the airport component based on code.
        this.SVC.name = result.AirportCode;
        var pageRoute = '/' + result.AirportCode.toLowerCase();

        if (pageRoute !== '') {
          this.isLoading = false;
          this.router.navigate([pageRoute]);
        }
        else {
          //Error
        }

        let favIcon: HTMLLinkElement = document.querySelector('#lkFavicon');
        favIcon.href = result.Icon;
      })
      .catch(error => { console.log(error); throw 'Error Loading the Context'; });
  }

  //onCalenderPopUp(args: FocusEventArgs): void {
  //   (this.datepickerObj as any).show();
  // }
}
